function catalogAccordion() {
    var btn = $('.catalog__accordion-btn');

    btn.on('click', function () {
        var that = $(this),
            parent = that.parents('.catalog__accordion'),
            body = parent.find('.catalog__accordion-body');

        that.toggleClass('active');
        body.slideToggle()
    })
}

$(document).ready(function () {
    catalogAccordion();
});